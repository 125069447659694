<template>
  <div class="flex h-full">
    <DetailsSidePanel class="side-panel">
      <template v-slot:heading>import communities</template>
      <template v-slot:text>
        <div>Select .csv file, validate and upload it to import the communities.</div>
        <button class="btn-primary mt-4" @click="downloadTemplate">Download csv template</button>
      </template>
    </DetailsSidePanel>
    <div class="flex-1 mx-8">
      <div class="flex flex-col items-center">
        <file-input
          v-model="uploadedFile"
          accept=".csv"
          class="file-uploader"
          title="please select a .csv file to upload."
          @input="changeUploadedFile"
        ></file-input>
        <div class="mt-10 flex flex-col items-center">
          <button class="btn-primary" @click="validateFile" :disabled="!uploadedFile || validating || importing">
            validate
          </button>
          <BeatLoader class="flex-1 mt-4" :loading="validating"/>
          <span v-if="validationError" class="text-error my-4 flex items-center">
            <Icon name="alert2" class="h-4 mr-1"/>
            validation failed: {{validationError}}
          </span>
          <span v-if="validated && !hasErrors" class="text-success my-4 flex items-center">
            <Icon name="complete" class="h-4 mr-1"/>
            the file was successfully validated.
          </span>
        </div>
      </div>
      <code v-show="importing" class="mt-6 text-sm">Importing...</code>
      <div v-if="processData" class="mt-6 flex flex-col text-sm">
        <code v-for="(error, index) in processData.errors" :key="index">
          line {{error.line}}:  {{error.field ? `Field ${error.field}. ` : ''}}{{error.message}}
        </code>
        <code>Skipped: {{processData.skipCount}}</code>
        <code>Passed: {{processData.writeCount}}</code>
      </div>
      <div v-if="imported" class="mt-6">
        <span v-if="hasErrors" class="text-error flex items-center">
          <Icon name="alert2" class="h-4 mr-1"/>communities import failed
        </span>
        <span v-else class="text-success flex items-center">
          <Icon name="complete" class="h-4 mr-1"/>
          {{processData.writeCount}} {{processData.writeCount === 1 ? 'community was' : 'communities were'}} successfully imported.
        </span>
      </div>
    </div>
    <ModalFooter
      :footer="{primaryButton:  'import' , tertiaryButton: 'cancel'}"
      :primary="importCommunities"
      :tertiary="close"
      :disablePrimary="!validated || hasErrors"
    />
  </div>
</template>

<script>
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from '@/mixins/NotifyMixin'
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import FileInput from "@/components/ui/FileInput";
  import BeatLoader from "@/components/ui/BeatLoader";
  import Icon from "@/components/ui/Icon";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import getEnvVariable from "@/utils/getEnvVariable";

  export default {
    name: 'CommunitiesImportView',
    components: {
      DetailsSidePanel,
      ModalFooter,
      FileInput,
      BeatLoader,
      Icon,
    },
    mixins: [ModalNavigation, NotifyMixin],
    data: function() {
      return {
        uploadedFile: null,
        validating: false,
        validated: false,
        validationError: '',
        importId: null,
        importing: false,
        imported: false,
        processData: undefined,
        pollingIntervalId: null,
      }
    },
    computed: {
      customerId: function () {
        return this.$route.params.customerId;
      },
      filePayload() {
        const formData = new FormData();
        formData.append('file', this.uploadedFile);
        return formData;
      },
      hasErrors() {
        return this.processData?.errors?.length > 0;
      },
    },
    methods: {
      changeUploadedFile(file) {
        if (!file) {
          this.reset();
        }
      },

      reset() {
        if (this.pollingIntervalId) {
          this.stopProcessStatusPolling();
        }
        this.validated = false;
        this.validationError = '';
        this.importId = null;
        this.imported = false;
        this.processData = undefined;
      },

      validateFile() {
        this.reset();
        this.validating = true;
        this.validationError = '';

        this.$authDataProvider.create('communitiesValidateImportFile', {
          customerId: this.customerId,
          data: this.filePayload,
        })
          .then((result) => {
            this.processData = result;
            this.startProcessStatusPolling(result.id);
          })
          .catch((error) => {
            this.validationError = error.message;
          });
      },

      importCommunities() {
        this.importing = true;

        this.$authDataProvider.create('communitiesImport', {
          customerId: this.customerId,
          data: this.filePayload,
        })
          .then((result) => {
            this.processData = result;
            this.startProcessStatusPolling(result.id);
          })
          .catch((error) => this.notifyError(error.message));
      },

      downloadTemplate() {
        const baseURL = getEnvVariable('VUE_APP_AUTH_API_URL');
        window.open(`${baseURL}/import-communities/download-example-file`);
      },

      fetchProcessStatus() {
        return this.$authDataProvider.get('communitiesImportStatus', {
          customerId: this.customerId,
          importId: this.importId,
        })
            .then((result) => {
              this.processData = result;

              if (result.status === 'COMPLETED') {
                if (this.validating) {
                  this.validated = true;
                  this.validationError = result.errors.length > 0 ? 'see errors below': '';
                } else {
                  this.imported = true;
                }

                this.stopProcessStatusPolling();
              }
            })
            .catch((error) => this.notifyError(error.message));
      },

      startProcessStatusPolling(syncId) {
        clearInterval(this.pollingIntervalId);
        this.importId = syncId;
        this.pollingIntervalId = setInterval(this.fetchProcessStatus, 3000);
      },

      stopProcessStatusPolling() {
        clearInterval(this.pollingIntervalId);
        this.validating = false;
        this.importing = false;
        this.importId = null;
      }
    },
    beforeUnmount() {
      this.stopProcessStatusPolling();
    }
  };
</script>
